<template>
  <div
    class="cb-message"
    :class="{'is-bot' : !data.isBot}">
    <span
      ref="icon"
      class="cb-message-icon">

      <avatar-guest />
    </span>
    <div
      :id="messageRef"
      :ref="messageRef"
      class="cb-message-text">
      <span class="time-hour">{{ timeHour }}</span>
      <template>
        <v-rating
          v-model="data.value"
          background-color="orange lighten-3"
          color="orange"
          :length="data.value"
          readonly
          dense
          large />
      </template>
    </div>
  </div>
</template>

<script>
  import chatUiMixin from '@/mixins/chat-ui'
  import AvatarGuest from './AvatarGuest'

  export default {
    components: { AvatarGuest },
    mixins: [ chatUiMixin ],
    data () {
      return {
        messageRef: `message-${this.$uuid.v4()}`
      }
    },
    computed: {
      data () {
        return this.record.data
      },
      timeHour () {
        return location.hostname === 'localhost' ? this.record.slug : ''
      }
    },
    mounted () {
      this.iconAnimation()
      this.$nextTick(() => this.messageAnimation(this.messageRef))
    },
    methods: {
      action () {
        this.$emit('action', true)
      }
    }
  }
</script>